<template>
  <div class="page-staff-af">
    <div class="box-top">
      <div class="fixed">
        <DataSearch :dateQuery="ruleForm" @change="changeDate"/>
      </div>
    </div>

    <h3 class="title">
      <strong>接待总览</strong>
    </h3>
    <div :class="itemClass">
      <div class="score" v-for="(item, idx) in totalAccount" :key="idx">
        <div class="num"><strong>{{item.value || '-'}}</strong></div>
        <div class="name" v-if="item.unit">{{item.title}}({{item.unit}})</div>
        <div class="name" v-else>{{item.title}}</div>
      </div>
    </div>

    <h3 class="title">
      <strong>接待客户明细</strong>
    </h3>
    <div class="sec" style="padding:0 0 0.5rem 0; margin-bottom: 0.5rem">
      <el-table class="box-tbl" 
          :data="tableData"
          @row-click="handleRowClick"
          >
          <el-table-column
            prop="order_no"
            width="70"
            label="主工单"
            >
          </el-table-column>
          <el-table-column
            prop="car_plate"
            width="70"
            label="车牌号"
            >
          </el-table-column>
          <el-table-column
            prop="in_time"
            width="80"
            label="进厂时间">
          </el-table-column>
          <el-table-column
            prop="invoice_time"
            width="80"
            label="开单时间">
          </el-table-column>
          <el-table-column
            prop="score"
            width="50"
            label="总分">
          </el-table-column>
        </el-table>
    </div>

    <NavBar tab="首页"></NavBar>
  </div>
</template>

<script>
import Vue from 'vue'
import { postForm, fetchGet } from '@/api'
import NavBar from '@/components/afNavbarDealer.vue';
import DataSearch from '@/components/afDataSearch.vue';
import * as echarts from 'echarts'
import { tagOpts, radarOpts } from '@/utils/chart'


export default {
  data() {
      return {
        order_by: 1,
        selPop: {
          target_model: false,
          user_level: false,
        },
        selObj: {
          target_model: '',
          user_level: '',
        },
        tagData: [],
        tagTab: 1,
        curTag: {},
        totalAccount: [],
        tableData: [],
        carModelOpts: [],
        userLevelOpts: [],
        inNum: {
          r1: '首次进店',
          r2: '首次进店',
          r3: '首次进店',
          r4: '首次进店',
        },
        inNumList: ['首次进店', '再次进店'],
        ruleForm: {
          date_type: '今日'
        },
        online: false,
        electricity: 0,
      }
  },
  computed:{
    itemClass() {
      if (this.totalAccount.length % 2 == 0) {
        return 'box-result sec';
      }
      return 'box-result even sec';
    }
  },
  components:{
    NavBar,
    DataSearch,
  },
  methods : {
    handleRowClick(r, e, c) {
      this.$router.push({name: 'afReceptionShow', query: {id:r.id}});
    },
    changeDate(v) {
      this.ruleForm = {
        ...this.ruleForm,
        ...v,
      }
      this.loadData();
    },
    selDateType(v) {
      this[v.key] = v.value;
    },
    changeTagTab(l) {
      this.tagTab = l;
      this.changeTag(this.curTag, l);
    },
    changeTag(item) {
      this.curTag = item || {};
      const d = this.curTag.dataList || [];
      const labelArr = d.map(o => o.label);
      const timesArr = d.map(o => o.value);
      const rateArr = d.map(o => o.rate);
      const valueArr = this.tagTab == 2 ? timesArr : rateArr;
      this.tagChart.clear();
      this.tagChart.setOption(tagOpts({
        labelArr,
        valueArr,
        tagTab: this.tagTab
      }));
    },
    
    onSort() {
      this.order_by = this.order_by == 1 ? 0 : 1;
      this.getList();
    },
    onSel(obj) {
      this.selPop = {};
      this.selObj = {
        ...this.selObj,
        ...obj
      }
      this.getList();
    },
    onToggle(k) {
      this.selPop = {
        [k]: !this.selPop[k],
      }
      setTimeout(() => {
        const dom = document.getElementById(this.selObj[k]);
        dom && dom.scrollIntoView({
          behavior: 'smooth',
        });
      }, 0);
    },
    getTotal(dq={}) {
      const {ruleForm} = this;
      fetchGet('h5/sa_seller/statisticalAnalysis1', {
        ...ruleForm,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }

          this.totalAccount = res.data.totalAccount||[];
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getList(dq={}) {
      const {ruleForm} = this;
      fetchGet('h5/sa_seller/statisticalAnalysis2', {
        ...ruleForm,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
        this.tableData = res.data || [];

        } else {
          this.$message.error(res.msg);
        }
      });
    },
    
    loadData() {
      this.getTotal();
      this.getList();
    },
  },
  created() {
    const {id, ...rest} = this.$router.currentRoute.query || {};
    this.ruleForm = {
      ...this.ruleForm,
      ...rest,
      admin_id: id
    };
    console.log(rest, this.ruleForm)
    this.loadData();
  },
  mounted () {
  },
}
</script>

<style lang="less">

.page-staff-af {
  background: #f2f2f2;
  min-height: 100vh;

  .el-radio__inner {
    background: url('~@/assets/radio.png') center center no-repeat;
    background-size: cover;
    border: 0;
    &::after {
      display: none;
    }
  }
  .el-radio__input.is-checked .el-radio__inner {
    background: url('~@/assets/radio_sel.png') center center no-repeat;
    background-size: cover;

  }
  .box-tbl {
    &.el-table::before {
      background-color: transparent;
    }
    &.el-table, tr, .el-table__cell, th.el-table__cell.is-leaf {
      background: transparent;
      line-height: 1.5 !important;
      border: 0;
      padding: 0;

      .cell {
        padding: 0.3rem 0.2rem;
      }
    }
    .el-table__header-wrapper {
      background: #E6F1F9;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      th{
        color: #666;
        font-size: 0.6rem;
        border: 0;
        font-weight: normal;
      }
    }

    .el-table__body-wrapper {
      tr {
        td {
          font-size: 0.6rem;
          border: 0;
          color: #000;
          line-height: 1.2;
        }
      }
    }
  }
  
  h3.title {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    font-size: 0.8rem;
    margin-top: 0.8rem;
    margin-bottom: 0.5rem;
    display: flex;

    >span {
      flex: 1;
      text-align: right;
      .el-radio__input {
        width: 20px;
      }
      .el-radio__label {
        padding-left: 0;
        color: #000;
      }
    }
  }

  .box-filter {
    padding: 1rem;
    .item {
      display: inline-block;
      margin-right: 0.5rem;
      padding: 0.25rem 0;
      cursor: pointer;
      .i {
        width: 1rem;
        height: 0.25rem;
        background: #e8e8e8;
        display: inline-block;
        margin-right: 0.25rem;
        vertical-align: middle;

        &.sel {
          background: #6F95FC;
        }
      }
    }
  }
  
  .box-tag {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 0.7rem;
      line-height: 2rem;
      width: 47%;
      text-align: center;
      border: 1px solid #F5F5F5;
      border-radius: 10px;
      background: #F5F5F5;

      &.sel {
        border: 1px solid #1890ff;
        color: #377EEA;
        background: #fff;
      }
    }
  }

  .box-result {
    padding: 0;
    display: flex;
    flex- wrap: wrap;
    &.even {
      .score:last-child {
        min-width: 100%;
      }
    }
    .score {
      min-width: 50%;
      text-align: center;
      border-top: 1px solid #EFEFEF;
      border-left: 1px solid #EFEFEF;
      padding: 1rem 0;

      &:nth-child(1), &:nth-child(2) {
        border-top: 0;
      }
      &:nth-child(odd) {
        border-left: 0;
      }

      .num {
        font-size: 0.9rem;
      }
      .name {
        font-size: 0.6rem;
        color: #788798;
      }
    }
  }

.box-top {
  height: 3rem;
  position: relative;

  .fixed {
    top: 0;
    width: 100%;
    max-width: 750px;
    z-index: 1000;
    background: url('~@/assets/af_bg_main.png') top center no-repeat;
    background-size: 100% auto;
    position: fixed;
    padding: 0.5rem 0.75rem;
  }
}

}
</style>
